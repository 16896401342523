import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import { ConfirmationNumber as TicketIcon, TableBar as Table } from "@mui/icons-material";
import { Add, Remove } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useAuthContext } from '../hooks/useAuthContext'

export default function CartItem({ item, changeTotal }) {
    const eventId = "6565d367f4b2e00299187eaa";
    const { user } = useAuthContext();
    const { name, price, currency, available, category, deposit, itemId, notes, people, includesTickets, capacity, tableTickets} = item;


    const [quantity, setQuantity] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setQuantity(item.quantity)
    }, [])

    const handleDecrease = async () => {

        if (!user) {
            // setError('You must be logged in');
            return;
        }
        setLoading(true);
        const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/cart/remove', {
            method: 'POST',
            body: JSON.stringify({itemId: itemId, eventId}),
            headers: {  
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        setLoading(false);
        if (response.ok) {
            setQuantity(quantity - 1);    
        }
        changeTotal(price);
    };

    const handleIncrease = async () => {

        if (!user) {
            // setError('You must be logged in');
            return;
        }
        setLoading(true);
        const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/cart/add', {
            method: 'POST',
            body: JSON.stringify({itemId: itemId, eventId}),
            headers: {  
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        if (response.ok) {
            setQuantity(quantity + 1);    
        }
        changeTotal(price);
        setLoading(false);
    };

    return (
        <>
            { category == "ticket" &&
            <Box sx={{
                display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center"
            }}>
                <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <Box sx={{display: "flex", gap: "5px"}}>
                        {!tableTickets &&
                        <>
                            {/* { !loading &&
                            <IconButton variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                                style={{
                                    color: "white",
                                    padding: "0px"
                                }}
                            >
                                <Remove fontSize="small"/>
                            </IconButton>
                            }
                            { loading &&
                            <IconButton disabled variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                                style={{
                                    color: "white",
                                    padding: "0px"
                                }}
                            >
                                <Remove fontSize="small"/>
                            </IconButton>
                            } */}
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "2px"}}>
                                {loading && <CircularProgress size="15px"/>}
                                <Typography fontWeight="700" fontSize="20px" color="white">{quantity}</Typography>
                            </Box>
                            {/* { !loading &&
                            <IconButton variant="outlined" onClick={() => handleIncrease()} size="small"
                            style={{
                                color: "white",
                                padding: "0px",
                            }}
                            >
                                <Add fontSize="small"/>
                            </IconButton>
                            } */}
                            {/* { loading &&
                            <IconButton disabled variant="outlined" onClick={() => handleIncrease()} size="small"
                            style={{
                                color: "white",
                                padding: "0px",
                            }}
                            >
                                <Add fontSize="small"/>
                            </IconButton>
                            } */}
                        </>}
                        {tableTickets && 
                        <>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "2px"}}>
                                {loading && <CircularProgress size="15px"/>}
                                <Typography fontWeight="700" fontSize="20px" color="white">{quantity}</Typography>
                            </Box>
                        </>
                        }
                    </Box>
                    {!tableTickets &&
                        <Box sx={{display: "flex", alignItems: "center", gap: "10px", marginTop: '3px'}}>
                            <Typography color="white" fontSize="18px">{item.name}</Typography>
                        </Box>
                    }
                    {tableTickets &&
                        <Box sx={{display: "flex", alignItems: "center", gap: "10px", marginTop: '2px'}}>
                            <Typography color="white" fontSize="18px">{item.name} - tickets part of your reservation</Typography>
                        </Box>
                    }
                </Box>
                <Typography textAlign="right" color="white" fontSize="18px">{price*quantity} {currency}</Typography>
                
            </Box>
            }
            { category == "reservation" &&
            <>
                <Box sx={{
                display: "flex", gap: "10px", justifyContent: "space-between",
                }}>
                    
                    <Box sx={{display: "flex", gap: "10px"}}>
                        <Box sx={{display: "flex", gap: "5px"}}>
                            {/* { !loading &&
                            <IconButton variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                                style={{
                                    color: "white",
                                    padding: "0px"
                                }}
                            >
                                <Remove fontSize="small"/>
                            </IconButton>
                            }
                            { loading &&
                            <IconButton disabled variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                                style={{
                                    color: "white",
                                    padding: "0px"
                                }}
                            >
                                <Remove fontSize="small"/>
                            </IconButton>
                            } */}
                            <Box sx={{display: "flex", justifyContent: "center", gap: "2px"}}>
                                {loading && <CircularProgress size="15px"/>}
                                <Typography fontWeight="700" fontSize="20px" color="white">{quantity}</Typography>
                            </Box>
                            {/* { !loading &&
                            <IconButton variant="outlined" onClick={() => handleIncrease()} size="small"
                            style={{
                                color: "white",
                                padding: "0px",
                            }}
                            >
                                <Add fontSize="small"/>
                            </IconButton>
                            } */}
                            {/* { loading &&
                            <IconButton disabled variant="outlined" onClick={() => handleIncrease()} size="small"
                            style={{
                                color: "white",
                                padding: "0px",
                            }}
                            >
                                <Add fontSize="small"/>
                            </IconButton>
                            } */}
                        </Box>
                        <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px"
                        }}>
                            <Box sx={{display: "flex", gap: "5px", marginTop: '3px'}}>
                                <Table sx={{color: 'white'}}/>
                                {!includesTickets &&
                                <Typography color="white" fontSize="18px">{name} - deposit {deposit}%, {people} people</Typography>
                                }
                                {includesTickets &&
                                <Typography color="white" fontSize="18px">{name} - {people} people</Typography>
                                }
                            </Box>
                            <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "3px"
                            }}
                            >
                            </Box>
                        </Box>
                    </Box>
                    {!includesTickets && 
                    <Typography textAlign="right" color="white" fontSize="18px">{deposit/100.0 * price * quantity} RON</Typography>
                    }
                    {includesTickets &&
                    <Typography textAlign="right" color="white" fontSize="18px">Includes {capacity} tickets</Typography>
                    }
                </Box>
                {/* <Box>
                    <Typography color="white" fontWeight="600">Notes</Typography>
                    <Typography color="white">{notes}</Typography>
                </Box> */}
            </>
            }
            {category != "ticket" && category != "reservation" &&
                <Box sx={{
                    display: "flex", gap: "10px", justifyContent: "space-between",
                }}>
                    <Box sx={{display: "flex", gap: "5px"}}>
                        <Typography color="white" fontSize="18px">
                            {name}
                        </Typography>
                    </Box>
                    <Typography textAlign="right" color="white" fontSize="18px">{price} RON</Typography>
                </Box>
                
            }
            {/* <Divider sx={{bgcolor: "#1a1c1f"}}/> */}
        </>
    )
}