import { Container, CssBaseline, Typography, Box, Divider, ButtonGroup, Button,
    useTheme, useMediaQuery, TextField, Card, CardContent, LinearProgress, CircularProgress, ImageList, List, ListItem } from "@mui/material"
import { ConfirmationNumber as TicketIcon, TableBar as TableIcon, Check} from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import Ticket from "../components/Ticket"
import Table from "../components/Table"
import Navbar from "../components/Navbar"
import { LocationOn as Location, MusicNote as Music, Headphones, FaceRetouchingOff as Minor, 
    Checkroom as Clothing, Phone, AccessTime, CalendarMonth} from "@mui/icons-material"
import ExtraService from "../components/ExtraService"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
    
    
    export default function Account() {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
        const [showPartypasses, setPartyPasses] = useState(true);
        const [showReservations, setReservations] = useState(false);

    
        const handlePartyPasses = () => {
            setReservations(false);
            setPartyPasses(true);
        }
    
        const handleReservations = () => {
            setPartyPasses(false);
            setReservations(true);
        }
    
        const { user } = useAuthContext();
        const [tickets, setTickets] = useState({});
        const [tables, setTables] = useState([]);
        const [extraServices, setExtraServices] = useState([]);
        const [events, setEvents] = useState([]);
        const [loading, setLoading] = useState(false);
        const [ticketTypes, setTicketTypes] = useState({});
    
        const fetchOrders = async() => {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/order/all', {
                headers: {'Authorization': `Bearer ${user.token}`}
            });
            // const response = await fetch('/api/order/all', {
            //     headers: {'Authorization': `Bearer ${user.token}`}
            // });
            const json = await response.json();
            if (response.ok) {
                setTickets(getTickets(json.orders));
                setEvents(getEvents(json.orders));
                setLoading(false);
            } else {
                console.log(json.error);
            }
            console.log(tickets)
        }
    
        const getTickets = (orders) => {
            const tickets = {};
            orders.forEach((o) => {
                o.order.items.forEach((item) => {
                    if (item.category === "ticket" && (item.eventId == "6738a2e7d377348dc30faf13")) {
                        if (tickets[item.eventId] === undefined) {
                            tickets[item.eventId] = [item]
                        } else {
                            tickets[item.eventId].push(item)
                        }
                    }
                })
            });
            return tickets
        }

        const getTicketTypes = (orders) => {
            const dict = {}
            orders.forEach((o) => {
                o.order.items.forEach((item) => {
                    if (item.category === "ticket" && (item.eventId == "6738a2e7d377348dc30faf13")) {
                        if (item.name in dict) {
                            dict[item.name] += 1;
                        } else {
                            dict[item.name] = 1;
                        }
                    }
                })
            })
            return dict
        }
    
        const getTables = (orders) => {
            const list = [];
            orders.forEach((o) => {
                o.order.items.forEach((item) => {
                    if (item.category === "reservation") {
                        list.push(item)
                    }
                })
            });
            return list
        }

        const getExtraServices = (orders) => {
            const list = [];
            orders.forEach((o) => {
                o.order.items.forEach((item) => {
                    if (item.category != "reservation" && item.category != "ticket") {
                        list.push(item)
                    }
                })
            });
            return list
        }

        const isInArray = (array, objectToCheck) => {
            return array.some(item => {
                return item.eventId === objectToCheck.eventId;
            })
        }
        const getEvents = orders => {
            const list = [];
            orders.forEach((o) => {
                if (!isInArray(list, o.eventInformation) && (o.eventInformation.eventName==="MYSTÈRE")) {
                    list.push(o.eventInformation);
                }
            })
            return list
        }
    
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
    
        const getUserInfo = async() => {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/user/information', {
                headers: {'Authorization': `Bearer ${user.token}`}
            });
            // const response = await fetch('/api/user/information', {
            //     headers: {'Authorization': `Bearer ${user.token}`}
            // });
            const json = await response.json();
            if (response.ok) {
                setFirstName(json.first_name);
                setLastName(json.last_name);
            } else {
                console.log(json.error);
            }
        }
    
        const fetchTables = async() => {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/table/', {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'eventid': "67165d7a51e7fdea93f086a8"
                },
            })
            const json = await response.json();
            if (response.ok) {
                console.log(json.tables)
                setTables(json.tables)
            } else {
                console.log("error")
            }
        }
        
        useEffect(() => {
            setLoading(true)
            if(user){
                fetchTables()
                fetchOrders();
                getUserInfo();
            }
        }, [user])
    
    
    
        return (
            <>  
                <Navbar/>
                <CssBaseline/>
                {/* <Navbar/> */}
                {loading && 
                    <>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
                            <CircularProgress/>
                        </Box>
                    </>
                }
                {!loading &&
                <Container maxWidth="lg" 
                sx={{display: "flex", flexDirection: "column", alignItems: "center",
                paddingTop: "50px", gap: "20px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "20px", width: "100%"}}>
                        <Typography variant="h2" sx={{fontSize: '40px'}}>
                            Hello, {firstName}!
                        </Typography>
                        <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                    </Box>
                    {events.length > 0 &&
                    <Box sx={{display: "flex", flexDirection: "column", width: "100%", gap: "20px"}}>
                        {events.map((event, index) => {
                            return (
                                <Box key={index}
                                sx={{display: "flex", flexDirection: "column", gap: "40px", width: "100%", flexWrap: "wrap"}}
                                >
                                    <Box
                                    sx={{display: "flex", flexDirection: "column", gap: "12px", width: "100%", flexWrap: "wrap"}}
                                    >
                                        <Typography variant="h3" fontSize="42px">
                                            {event.eventName}
                                        </Typography>
                                        
                                        <Box
                                        sx={{display: "flex", flexDirection: "column", gap: "15px", flexWrap: 'wrap'}}
                                        >   
                                            <Box
                                            sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                            >   
                                                <Location sx={{color:"white", fontSize: "20px"}}/>
                                                <Typography variant="h3" fontSize="15px" color="white" fontWeight="600">
                                                    {event.location}
                                                </Typography>
                                            </Box>
                                            <Box
                                            sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                            >   
                                                <CalendarMonth sx={{color:"white", fontSize: "20px"}}/>
                                                {event.eventName === "LAZARE" &&
                                                    <Typography color="white" fontSize="15px" fontWeight="600">
                                                    September 20th
                                                    </Typography>
                                                }
                                                <Typography color="white" fontSize="15px" fontWeight="600">
                                                    December 7th
                                                </Typography>
                                            </Box>
                                            <Box
                                            sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                            >   
                                                <AccessTime sx={{color:"white", fontSize: "20px"}}/>
                                                {event.eventName === "LAZARE" &&
                                                    <Typography color="white" fontSize="15px" fontWeight="600">
                                                        16:00 - END
                                                    </Typography>
                                                }
                                                {event.eventName != "LAZARE" &&
                                                <Typography color="white" fontSize="15px" fontWeight="600">
                                                    23:00 - END
                                                </Typography>
                                                }
                                            </Box>
                                            <Box
                                            sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                            >   
                                                <Headphones sx={{color:"white", fontSize: "20px"}}/>
                                                <Typography variant="h3" fontSize="15px" color="white">
                                                    {event.performers}
                                                </Typography>
                                            </Box>
                                            <Box
                                            sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                            >   
                                                <Phone sx={{color:"white", fontSize: "20px"}}/>
                                                <Typography variant="h3" fontSize="15px" color="white">
                                                    +40744.888.111
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{bgcolor: "#1a1c1f", width: "100%", marginTop: "5px"}}/>
                                    {tables && tables.length > 0 &&
                                    <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "5px",
                                        width: "100%"
                                    }}
                                    >
                                        <Typography variant="h3">
                                            TABLES
                                        </Typography>
                                        <ImageList gap={5} sx={{display: "flex", width: "100%"}}>
                                            {tables.map((table, index) => {
                                                return (
                                                    <>
                                                        <Table key={table._id} person={table.person} table={table} />
                                                    </>
                                                )
                                            })}
                                        </ImageList>
                                    </Box>
                                    }
                                    <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "5px",
                                        width: "100%"
                                    }}
                                    >
                                        <Typography variant="h3">
                                            TICKETS
                                        </Typography>
                                        <Divider sx={{bgcolor: "#1a1c1f", width: "100%", marginTop: "5px"}}/>
                                        {tickets[event.eventId].length == 0 &&
                                        <Box sx={{display: "flex", justifyContent:"center", flexDirection: "column", alignItems: "center", width: "100%", height: "400px"}}>
                                            <TicketIcon sx={{color: "#9CA3AF", fontSize: "70px"}}/>
                                            <Typography color="#9CA3AF">No Tickets Found</Typography>
                                        </Box>
                                        }
                                        {tickets[event.eventId].length > 0 &&
                                        <>
                                        <Box
                                        sx={{
                                            display: "flex", 
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            paddingTop: "10px",
                                            gap: "10px"
                                        }}
                                        >
                                        </Box>
                                        <ImageList gap={5} sx={{display: "flex", width: "100%"}}>
                                            {tickets[event.eventId].map((ticket, index) => {
                                                return (
                                                    <Ticket key={index} firstName={firstName} lastName={lastName} qrCode={ticket.qrCode} name={ticket.name}/>
                                                )
                                            })}  
                                        </ImageList>
                                        </>
                                        }
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>}
                </Container>}
                <Footer/>
                {/* <Footer/> */}
            </>
        )
    }