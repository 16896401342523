import { Container, CssBaseline, Box, Typography, Divider, Button, Radio, ButtonGroup, Slide, CircularProgress, TextField, Select, MenuItem,
    Checkbox,
    Alert,
    Collapse} from "@mui/material"
    import { LocationOn as Location, MusicNote as Music, Headphones, FaceRetouchingOff as Minor, 
         Checkroom as Clothing, Phone} from "@mui/icons-material"
    import TicketType from "../components/TicketType"
    import { useState } from "react"
    import { useAuthContext } from "../hooks/useAuthContext"
    import { Link } from "react-router-dom"
    import Navbar from "../components/Navbar"
    import { useEffect } from "react"
    import { useNavigate } from "react-router-dom"
    import { ConfirmationNumber as TicketIcon } from "@mui/icons-material"
    import { useSearchParams } from "react-router-dom";
    
    
    
    export default function Modify() {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams()
        const tableid = searchParams.get('tableId')
        const eventid = searchParams.get('eventId')
        const [total, setTotal] = useState(0);
    
        const { user } = useAuthContext();
    
        const [items, setItems] = useState({});
    
    
        const handleTicket = (itemId, quantity, price) => {
            if (quantity > 0) {
                if (itemId in items) {
                    items[itemId].quantity += 1;
                } else {
                    items[itemId] = {itemId, quantity: 1}
                }
                setTotal(total + price)
            } else if (quantity < 0) {
                if (itemId in items) {
                    if (items[itemId].quantity > 1) {
                        items[itemId].quantity -= 1
                        setTotal(total - price)
                    } else if(items[itemId].quantity == 1) {
                        delete items[itemId];
                        setTotal(total - price)
                    }
                }
            }
        }

        // const completePurchase = async () => {
        //     const itemsToPurchase = [];
        //     for (let key in items) {
        //         itemsToPurchase.push(items[key]);
        //     }
        //     setLoadingButton(true);
        //     const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/cart/items', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${user.token}`
        //         },
        //         body: JSON.stringify({items: itemsToPurchase, eventId: eventId})
        //     })
        //     if (response.ok) {
        //         navigate('/cart', { replace: true });
        //         setLoadingButton(false);
        //     } else {
        //         const json = await response.json();
        //         setLoadingButton(false);
        //         setErrorMessage(json.error);
        //         setShowError(true);
        //         setTimeout(() => {
        //             setShowError(false);
        //         }, 2500);
        //         setTimeout(() => {
        //             setErrorMessage(null);
        //         }, 2700);
        //     } 
        // }
        const [loadingButton, setLoadingButton] = useState(false);
    
        const [errorMessage, setErrorMessage] = useState(null);
        const [showError, setShowError] = useState(false);
        const [successMessage, setSuccessMessage] = useState(null);
        const [showSuccess, setShowSuccess] = useState(false);
    
        const [loading, setLoading] = useState(false);
    
        const [ticket, setTicket] = useState(undefined)
        const [allowedTickets, setAllowedTickets] = useState(undefined)
        const [quantity, setQuantity] = useState(0)

        const fetchItems = async() => {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/table/additional-items', {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'tableid': tableid,
                    'eventid': eventid
                }
            });
            // const response = await fetch('/api/order/all', {
            //     headers: {'Authorization': `Bearer ${user.token}`}
            // });
            const json = await response.json();
            if (response.ok) {
                setAllowedTickets(json.allowedTickets)
                setTicket(json.ticket)
            } else {
                console.log(json.error);
            }
        }
    
    
        useEffect(() => {
            const fetch = async() => {
                if (!eventid || !tableid) {
                    navigate('/404', { replace: true });
                } else {
                    await fetchItems();
                }
            }
            fetch();
        }, [])

        const handleContinue = async() => {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/cart/modify-reservation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({tableId: tableid, eventId: eventid, quantity})
            })
            const json = await response.json();
            if (response.ok) {
                navigate('/cart', { replace: true });
            } else {
                console.log("Error")
            }
        }
    
    
    
    
        return (
            <>
                <Navbar/>
                <CssBaseline/>
                {loading && 
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
                   <CircularProgress/>
                </Box>}
                {!loading && <>
                <Box
                sx={{
                    position: "fixed",
                    bottom: "90px",
                    right: "5px",
                    zIndex: "100",
                    maxWidth: "90%"
                }}
                >
                    <Slide direction="left" in={showSuccess && (successMessage != null)}>
                        <Alert>{successMessage}</Alert>
                    </Slide>
                </Box>
                <Box
                sx={{
                    position: "fixed",
                    bottom: "90px",
                    right: "5px",
                    zIndex: "100",
                    maxWidth: "90%"
                }}
                >
                    <Slide direction="left" in={showError && (errorMessage != null)}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Slide>
                </Box>
                <Box
                sx={{
                }}>
                    <Box
                    sx={{
                        paddingTop: "50px", paddingBottom: "100px",
                    }}
                    >
                        <Container maxWidth="lg" 
                        sx={{
                            display: "flex", flexDirection: "column", gap: "20px", minHeight: "100vh"
                        }}>
                            <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                            <Box
                            sx={{display: "flex", gap: "10px", alignItems: 'center', width: "100%", textAlign: "center"}}>
                                <Typography variant="h2" fontSize="22px">
                                    Add additional tickets to your table reservation
                                </Typography>
                                {ticket && allowedTickets &&
                                    <Select sx={{
                                    backgroundColor: "#1C1C1D",
                                    maxWidth: "70px",
                                    minWidth: "70px",
                                    }}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    value={quantity}
                                    >
                                        {Array.from({length: allowedTickets - 1 + 1}, (_, index) => (
                                            <MenuItem key={index} value={1 + index}>{1 + index}</MenuItem>
                                        ))}
                                    </Select>
                                }
                            </Box>
                            <Button onClick={()=> handleContinue()} variant="contained" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "white", color:"black", width: 'auto',
                            ":hover": {backgroundColor: "#BEC3CB", color: "black"}}}>
                                <Typography fontWeight="600">Continue</Typography>
                            </Button>
                        </Container>
                    </Box>
                </Box>
                </>}
            </>
        )
    }