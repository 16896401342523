import { Container, CssBaseline, Typography, Box, Divider, Button,
    useTheme, useMediaQuery, CircularProgress
    } from "@mui/material";
    // import { Navbar } from "../components/Navbar";
    import Partycard from "../components/Partycard";
    import { FavoriteBorder as Follow } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
    


export default function Home() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        const fetchEvents = async () => {
    
            try {
                const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/organizer/all-events', {
                    headers: { 'organizerid': '6554074c3df68ffc219743b6' }
                });
    
                if (!response.ok) {
                    const errorJson = await response.json();
                    console.log(errorJson.error); // Log the error from the response
                    return; // Exit if there is an error
                }
    
                const json = await response.json();
                setEvents(json.events);
            } catch (error) {
                console.error("Fetch error:", error); // Log any fetch error
            } finally {
                setLoading(false);
            }
        };
        setLoading(true)
        fetchEvents(); // Call the function to fetch events
    }, []); // Empty dependency array means this runs once on mount

    return (
        <>
            <Navbar/>
            <CssBaseline/>
            {loading && 
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
               <CircularProgress/>
            </Box>}
            {!loading &&
            <Container maxWidth="lg"
            sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "35px",paddingTop: "35px", paddingBottom: "25px",}}
            >
                {events && events.map((event) => {
                    return (
                        <Partycard key={event._id} eventId={event._id} eventName={event.eventName} date={event.date} hour={event.hour} location={event.location} imageUrl={event.imageUrl} />
                    )
                })}
                {events.length === 0 && 
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "35px",paddingTop: "35px", paddingBottom: "25px", height: "55vh"}}>
                    <Typography color="white" fontSize="30px" textAlign="center">No upcoming events</Typography>
                </Box>
                }
            </Container>
            }
            <Footer/>
        </>
    )
}